<template>
  <v-dialog v-model="showDialog" width="400" class="rounded-0">
    <v-card class="rounded-0" tile>
      <v-app-bar elevation="0" color="primary" class="px-2" dark height="80">
        <div>
          <div class="ma-0 pa-0 text-body-2">
            {{
              isRemovingFromShift
                ? $ml.get("remove_user_from_a_group")
                : $ml.get("add_user_to_group")
            }}
          </div>
          <div
            class="ma-0 pa-0 font-weight-bold text-h6 text-truncate text-wrap"
            style="max-width: 320px"
          >
            {{ selectedUserName }}
          </div>
        </div>
        <v-spacer />
        <v-btn icon @click="showDialog = false" dark class="ml-auto">
          <v-icon v-text="'mdi-close'" />
        </v-btn>
      </v-app-bar>

      <v-card-text class="mt-4">
        <v-form style="width: 100%">
          <v-combobox
            clearable
            multiple
            v-model="userGroups"
            :items="isRemovingFromShift ? userShifts : shiftsUserIsNotIn"
            item-text="name"
            item-value="key"
            :label="`${$ml.get('choose')} ${$ml.get('groups').toLowerCase()}`"
            class="mt-5"
            small-chips
            persistent-placeholder
            style="max-height: 100%"
          >
            <template v-slot:selection="{ attrs, item, parent, selected }">
              <v-chip
                v-if="item === Object(item)"
                v-bind="attrs"
                :input-value="selected"
                small
              >
                <span class="pr-2">
                  {{ item.name }}
                </span>
                <v-icon
                  small
                  @click="parent.selectItem(item)"
                  v-text="'mdi-close-circle'"
                />
              </v-chip>
            </template>
          </v-combobox>
        </v-form>
      </v-card-text>
      <v-card-actions class="mx-1 pb-3">
        <v-spacer />
        <v-btn
          color="secondary lighten-2"
          text
          :disabled="showLoading"
          @click="$emit('close')"
        >
          {{ $ml.get("cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="
            isRemovingFromShift ? (shiftRemovalDialog = true) : editUserShift()
          "
          :loading="showLoading"
        >
          {{ $ml.get("confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <!-- DIALOG DE CONFIRMAÇÃO -->
    <HeaderDialog
      id="show_delete_confirmation"
      :title="selectedUserName"
      :subtitle="$ml.get('remove_user_from_a_group')"
      :show="shiftRemovalDialog"
      :loading="loadingSelectedGroup"
      width="620"
      closeText="cancel"
      @close="closeDialog"
      @action="editUserShift"
    >
      <template v-slot:body>
        <p class="word-break">
          {{ $ml.get("confirm_delete_user_from_group_label") }}
          <span v-if="userGroups" class="font-weight-bold">
            {{ userGroups.name }}
          </span>
        </p>
      </template>
    </HeaderDialog>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import HeaderDialog from "@/components/base/HeaderDialog";
export default {
  name: "AddOrRemoveUserShift",
  components: { HeaderDialog },
  props: {
    show: { type: Boolean, required: true },
    isRemovingFromShift: { type: Boolean, default: false },
  },
  data() {
    return {
      userGroups: null,
      shiftRemovalDialog: false,
      showLoading: false,
    };
  },
  watch: {
    showDialog() {
      if (this.showDialog) {
        this.userGroups = null;
      }
    },
  },
  computed: {
    ...mapGetters([
      "selectedUserName",
      "selectedUser",
      "groups",
      "loadingSelectedGroup",
    ]),

    showDialog: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("close");
      },
    },

    currentUserGroups() {
      if (this.selectedUser) {
        return this.selectedUser.rule_group;
      } else {
        return [];
      }
    },

    shiftsUserIsNotIn() {
      return this.groups.filter(
        (group) => !this.currentUserGroups.includes(group.key)
      );
    },

    userShifts() {
      return this.groups.filter((group) =>
        this.currentUserGroups.includes(group.key)
      );
    },
  },
  methods: {
    ...mapActions([
      "bulkEditGroupMembers",
      "setSnackbarDefault",
      "setSnackbarCustomMessage",
      "updateUsersOnStore",
    ]),
    ...mapMutations(["setSelectedUser"]),
    async editUserShift() {
      let groupsName = this.userGroups.map(({ name }) => name).toString();
      let payload = {
        custom_response: true,
        key: this.userGroups.map(({ key }) => key),
        payload: {
          users: [this.selectedUser.email],
        },
        is_delete: this.isRemovingFromShift,
      };
      this.showLoading = true;
      // Aqui vai toda a lógica de adicionar mais de 1 group em 1 user
      await this.bulkEditGroupMembers(payload)
        .then((data) => {
          const user = data.user;
          this.setSelectedUser(user);
          this.updateUsersOnStore([user]);
          this.setSnackbarCustomMessage({
            message: `${this.selectedUser.email} ${this.$ml.get(
              this.isRemovingFromShift
                ? "success_remove_user_from_group"
                : "success_add_user_to_a_group"
            )} ${groupsName}`,
            color: "success",
          });
        })
        .catch((error) => {
          this.setSnackbarDefault({
            message: this.isRemovingFromShift
              ? "error_remove_user_from_group"
              : "error_add_user_to_shift",
            color: "error",
          });
          console.error("bulkEditGroupMembers()", error);
        })
        .finally(() => {
          this.closeDialog();
          this.showLoading = false;
        });
      this.$emit("update");
      this.$emit("close");
    },

    closeDialog() {
      this.shiftRemovalDialog = false;
    },
  },
};
</script>
