<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-icon right :color="color" v-bind="attrs" v-on="on">
        {{ icon }}
      </v-icon>
    </template>
    <span>{{ text }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "Tooltip",
  props: {
    color: { type: String, default: "primary" },
    text: { type: String, required: true },
    icon: { type: String, default: "mdi-umbrella-beach" },
  },
};
</script>
