<template>
  <v-container>
    <HeaderDialog
      width="600"
      :show="vacationDialog"
      :loading="loading"
      id="set_vacation"
      :title="$ml.get('vacation_title')"
      :subtitle="isInBatch ? $ml.get('vacation_subtitle') : userEmail"
      :actionText="$ml.get('confirm')"
      :disabledAction="!hasChosenDates"
      closeText="cancel"
      @action="save"
      @close="closeDialog"
    >
      <template v-slot:body>
        <v-row class="pa-0 ma-0 mt-0">
          <p>{{ $ml.get("vacation_description") }}</p>

          <v-col>
            <Alert dense type="info" class="mb-2 mx-0">
              <span v-html="$ml.get('vacation_settings_tip')" />
            </Alert>
          </v-col>

          <v-col cols="12">
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRangeText"
                  :label="$ml.get('vacation_placeholder')"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dates"
                :min="today"
                range
                class="justify-end"
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  :disabled="!hasChosenDates"
                  @click="menu = false"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </template>
    </HeaderDialog>

    <VacationSignDialog
      :show="showDialogApplyVacationMessage"
      @action="redirectToSign"
      @close="
        showDialogApplyVacationMessage = false;
        resetDates();
      "
    />
  </v-container>
</template>
<script>
import HeaderDialog from "@/components/base/HeaderDialog";
import { formatDateUTC, today } from "@/helpers/utils";
import { mapActions, mapGetters } from "vuex";
import VacationSignDialog from "@/components/users/VacationSignDialog.vue";

export default {
  name: "Vacation",
  components: { HeaderDialog, VacationSignDialog },
  props: {
    show: { type: Boolean, required: true },
    user: { type: Object },
    emails: { type: Array, default: () => [] },
  },
  data: () => ({
    dates: [],
    inputDates: [],
    menu: false,
    loading: false,
    today,
    showDialogApplyVacationMessage: false,
  }),

  computed: {
    ...mapGetters([]),

    hasChosenDates() {
      return this.dates.length === 2;
    },

    dateRangeText() {
      const startDateIndex = 0;
      const endDateIndex = 1;
      if (!this.inputDates[startDateIndex] || !this.inputDates[endDateIndex]) {
        return null;
      }
      return `${formatDateUTC(this.inputDates[startDateIndex])} ${this.$ml.get(
        "until"
      )} ${formatDateUTC(this.inputDates[endDateIndex])}`;
    },

    vacationDialog: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("close");
      },
    },

    userEmail() {
      return this.user ? this.user.email : "";
    },

    isInBatch() {
      return !!this.emails.length;
    },

    userKey() {
      return this.user ? this.user.key : "";
    },

    isOnVacation() {
      return this.user ? this.user.is_on_vacation : false;
    },

    urlApplyVacationMessage() {
      const startDate = this.dates[0];
      const endDate = this.dates[1];
      return `${process.env.VUE_APP_SUITE_BASE_URL}/sign/users/apply?vacationStart=${startDate}&vacationEnd=${endDate}`;
    },
  },

  methods: {
    ...mapActions(["setUsersOnVacationInBatch", "setUserOnVacation"]),

    formatDateUTC,

    async save() {
      this.loading = true;
      const payload = {
        start_date: this.dates[0],
        end_date: this.dates[1],
      };

      if (this.isInBatch) {
        payload["users"] = this.emails;
        await this.setUsersOnVacationInBatch(payload);
      } else {
        payload["key"] = this.userKey;
        await this.setUserOnVacation(payload);
      }
      this.showDialogApplyVacationMessage = true;
      this.loading = false;
      this.$emit("close");
    },

    redirectToSign() {
      window.open(this.urlApplyVacationMessage);
      this.resetDates();
      this.showDialogApplyVacationMessage = false;
    },

    closeDialog() {
      this.dates = [];
      this.vacationDialog = false;
    },

    resetDates() {
      this.dates = [];
    },
  },

  watch: {
    menu(new_value) {
      if (!new_value && this.hasChosenDates) {
        const startDateIndex = 0;
        const endDateIndex = 1;
        const startDate = this.dates[startDateIndex];
        const endDate = this.dates[endDateIndex];
        if (new Date(startDate) > new Date(endDate)) {
          this.dates.sort();
        }
      }
    },
    dates() {
      this.inputDates = [...this.dates];
    },
    vacationDialog(new_value) {
      if (new_value && !this.isInBatch && this.isOnVacation) {
        this.inputDates = [
          this.user.start_vacation_date,
          this.user.end_vacation_date,
        ];
      }
    },
  },
};
</script>

<style></style>
