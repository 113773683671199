<template>
  <ReportsPaginated
    titleKey="reports_loginlogout_title"
    :companyView="companyView"
    :getterUrl="getterUrl"
    :userEmail="userEmail"
    @tableOptions="tableOptions = $event"
  />
</template>
<script>
import ReportsPaginated from "@/components/logs/ReportsPaginated.vue";

export default {
  name: "ReportsLoginLogout",
  components: { ReportsPaginated },
  props: {
    userEmail: { type: String },
    companyView: { type: Boolean, default: () => true },
  },
  data: () => {
    return {
      getterUrl: `${process.env.VUE_APP_API_BASE_URL}/logs/challenge-errors/company`,
    };
  },
};
</script>
