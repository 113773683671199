<template>
  <v-container>
    <HeaderDialog
      width="600"
      :show="showChangePassword"
      :loading="loadingUsersChangePassword"
      id="change_password"
      :title="$ml.get('reset_password')"
      :subtitle="isChangingUsersPassword ? '' : userEmail"
      :actionText="$ml.get('reset')"
      :disabledAction="canNotSavePassword"
      closeText="cancel"
      @action="save"
      @close="
        errorCode = null;
        showChangePassword = false;
      "
    >
      <template v-slot:body>
        <v-row class="pa-0 ma-0 mt-0">
          <p>
            <span v-html="$ml.get('reset_password_label')" />
            <span
              class="link primary--text font-weight-bold"
              @click="$router.push('/password-settings')"
            >
              {{ $ml.get("more_link_text") }} </span
            >.
          </p>

          <div class="caption font-weight-medium mb-4">
            <div class="my-1">
              <v-icon
                v-text="hasMinLength ? 'mdi-check-circle' : 'mdi-close-circle'"
                :color="hasMinLength ? 'success' : 'error'"
                size="22"
                class="mr-2"
                small
              />
              {{ $ml.get("min_len_label") }}
              {{ passwordStrength.min_len }}
            </div>
            <div v-if="rulesKeys.includes('has_lower')" class="my-1">
              <v-icon
                v-text="hasLower ? 'mdi-check-circle' : 'mdi-close-circle'"
                :color="hasLower ? 'success' : 'error'"
                size="22"
                class="mr-2"
                small
              />
              {{ $ml.get("checkbox_lowercase_label") }}
            </div>

            <div v-if="rulesKeys.includes('has_upper')" class="my-1">
              <v-icon
                v-text="hasUpper ? 'mdi-check-circle' : 'mdi-close-circle'"
                :color="hasUpper ? 'success' : 'error'"
                size="22"
                class="mr-2"
                small
              />
              {{ $ml.get("checkbox_uppercase_label") }}
            </div>
            <div v-if="rulesKeys.includes('has_number')" class="my-1">
              <v-icon
                v-text="hasNumber ? 'mdi-check-circle' : 'mdi-close-circle'"
                :color="hasNumber ? 'success' : 'error'"
                size="22"
                class="mr-2"
                small
              />
              {{ $ml.get("checkbox_numbers_label") }}
            </div>
            <div v-if="rulesKeys.includes('has_symbol')" class="my-1">
              <v-icon
                v-text="hasSymbol ? 'mdi-check-circle' : 'mdi-close-circle'"
                :color="hasSymbol ? 'success' : 'error'"
                size="22"
                class="mr-2"
                small
              />
              {{ $ml.get("checkbox_symbols_label") }}
            </div>
          </div>

          <v-form style="width: 100%" class="ma-0 pa-0">
            <!-- CAMPO DE SENHA -->
            <v-text-field
              v-model="userPassword.password"
              :type="showPasswordMask ? 'password' : 'text'"
              :label="$ml.get('new_password')"
              color="primary"
              :rules="companyRules"
              class="mt-2"
              loading
              :error="!!errorCode"
              :error-messages="customErrorMessage"
              @input="errorCode = null"
            >
              <template v-slot:append>
                <v-icon
                  @click="showPasswordMask = !showPasswordMask"
                  v-text="showPasswordMask ? 'mdi-eye' : 'mdi-eye-off'"
                />
              </template>
              <template v-slot:progress>
                <v-progress-linear
                  color="success"
                  :value="progress"
                  absolute
                  height="7"
                ></v-progress-linear>
              </template>
            </v-text-field>

            <!-- CAMPO DE CONFIRMAÇÃO DA SENHA -->
            <v-text-field
              class="mt-4"
              :disabled="thereIsNoPassword"
              v-model="userPassword.confirm_password"
              :type="showPasswordConfirmMask ? 'password' : 'text'"
              autocomplete="off"
              :label="`${$ml.get('confirm_new_password')}`"
              style="max-height: 65px"
              :error="userPassword.password != userPassword.confirm_password"
              hide-details
            >
              <template v-slot:append>
                <v-icon
                  :disabled="thereIsNoPassword"
                  @click="showPasswordConfirmMask = !showPasswordConfirmMask"
                  v-text="showPasswordConfirmMask ? 'mdi-eye' : 'mdi-eye-off'"
                />
              </template>
            </v-text-field>

            <v-input
              :value="userPassword.password == userPassword.confirm_password"
              :rules="[rules.equalPasswords]"
            />

            <v-checkbox
              v-model="userPassword.force_change"
              class="mt-3"
              style="width: fit-content"
            >
              <template v-slot:label>
                <div class="d-flex align-center">
                  {{ $ml.get("ask_reset_password_first_access") }}
                  <v-tooltip top max-width="300">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        size="18"
                        class="ml-1"
                        v-text="'mdi-help-circle-outline'"
                      />
                    </template>
                    <span>{{
                      $ml.get("ask_reset_password_first_access_tooltip")
                    }}</span>
                  </v-tooltip>
                </div>
              </template>
            </v-checkbox>
            <v-checkbox
              v-model="force_logout"
              class="mt-0"
              style="width: fit-content"
            >
              <template v-slot:label>
                <div class="d-flex align-center">
                  {{ $ml.get("ask_force_logout_reset_password") }}
                  <v-tooltip top max-width="300">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        size="18"
                        class="ml-1"
                        v-text="'mdi-help-circle-outline'"
                      />
                    </template>
                    <span>{{
                      $ml.get("ask_force_logout_reset_password_tooltip")
                    }}</span>
                  </v-tooltip>
                </div>
              </template>
            </v-checkbox>
          </v-form>
        </v-row>
      </template>
    </HeaderDialog>
    <HeaderDialog
      :show="feedbackDialog"
      :title="$ml.get('rate_your_experience')"
      :subtitle="$ml.get('rate_your_experience_subtitle')"
      width="600"
      readOnly
      customFooter
      :persistent="false"
      @close="feedbackDialog = false"
    >
      <template #body>
        <FeedbackRating
          v-if="feedbackDialog"
          action="change_user_password"
          show-dismiss
          rating-class="d-flex justify-start ml-1"
          width="400"
          @close="feedbackDialog = false"
        />
      </template>
    </HeaderDialog>
  </v-container>
</template>
<script>
import HeaderDialog from "@/components/base/HeaderDialog";
import FeedbackRating from "@/components/forms/FeedbackRating";
import { setFirebaseEvent } from "@/helpers/registerAnalytics";
import { mapActions, mapGetters } from "vuex";
import { renewFeedback } from "@/helpers/utils";
export default {
  name: "ChangeUserPassword",
  components: { HeaderDialog, FeedbackRating },
  props: {
    show: { type: Boolean, required: true },
    user: { type: Object, default: () => {} },
    userKey: { type: String, default: "" },
    emails: { type: Array, default: () => [] },
  },
  data() {
    return {
      userPassword: {
        key: this.userKey,
        password: "",
        confirm_password: "",
        force_change: false,
      },
      rules: {
        equalPasswords: (value) =>
          value || this.$ml.get("passwords_do_not_match"),
        min_len: (v) => v.length >= this.minimumSize,
        has_upper: (v) => /[A-Z]/.test(v),
        has_lower: (v) => /[a-z]/.test(v),
        has_number: (v) => /\d/.test(v),
        //eslint-disable-next-line
        has_symbol: (v) => /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(v),
      },
      showPasswordMask: true,
      showPasswordConfirmMask: true,
      passwordStrengthRules: [],
      rulesKeys: [],
      errorCode: null,
      force_logout: true,
      feedbackDialog: false,
    };
  },
  watch: {
    showChangePassword() {
      if (this.showChangePassword) {
        this.initRules();
        this.userPassword = {
          key: this.userKey,
          password: "",
          confirm_password: "",
          force_change: false,
        };
      }
    },
    feedbackDialog(value) {
      if (!value && !this.userHasAlreadySentFeedback) {
        setFirebaseEvent(
          this.mainDomain,
          "feedback_not_sent",
          "after_change_user_password",
          this.currentUser.email
        );
      }
    },
  },
  computed: {
    ...mapGetters([
      "loadingUsersChangePassword",
      "passwordStrength",
      "currentUserFeedbacks",
      "mainDomain",
      "currentUser",
    ]),

    userHasAlreadySentFeedback() {
      return renewFeedback(this.currentUserFeedbacks.change_user_password);
    },

    companyRules() {
      return this.passwordStrengthRules; // Determina as regras que o campo da senha precisa ter
    },

    hasMinLength() {
      return this.userPassword.password.length >= this.minimumSize;
    },

    hasSymbol() {
      return /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(
        this.userPassword.password
      );
    },

    hasUpper() {
      return /[A-Z]/.test(this.userPassword.password);
    },

    hasLower() {
      return /[a-z]/.test(this.userPassword.password);
    },

    hasNumber() {
      return /\d/.test(this.userPassword.password);
    },

    completedSteps() {
      return this.rulesKeys
        .map((rule_key) => {
          if (rule_key === "has_symbol") {
            return this.hasSymbol;
          } else if (rule_key === "has_lower") {
            return this.hasLower;
          } else if (rule_key === "has_upper") {
            return this.hasUpper;
          } else if (rule_key === "has_number") {
            return this.hasNumber;
          } else {
            return this.hasMinLength;
          }
        })
        .filter((item) => item === true).length;
    },

    completed() {
      return this.progress === 100;
    },

    progress() {
      return (this.completedSteps * 100) / this.rulesKeys.length;
    },

    color() {
      return ["error", "warning", "success"][
        Math.floor(this.progress / this.rulesKeys.length)
      ];
    },

    minimumSize() {
      return this.passwordStrength.min_len;
    },

    showChangePassword: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("close");
      },
    },
    userEmail() {
      return this.user ? this.user.email : "";
    },
    thereIsNoPassword() {
      return this.userPassword.password === "";
    },
    canNotSavePassword() {
      return (
        this.thereIsNoPassword ||
        this.userPassword.confirm_password === "" ||
        this.userPassword.confirm_password !== this.userPassword.password ||
        !this.completed
      );
    },

    isChangingUsersPassword() {
      return !!this.emails.length;
    },

    customErrorMessage() {
      const inputErrors = {
        SAME_PASSWORD: "is_the_same",
      };
      let errorCode = inputErrors[this.errorCode] || this.errorCode;
      return errorCode ? this.$ml.get(errorCode) : "";
    },
  },
  methods: {
    ...mapActions([
      "changeUserPassword",
      "changeUsersPassword",
      "forceUsersLogout",
      "forceLogout",
    ]),

    async save() {
      let errorCode;
      if (this.isChangingUsersPassword) {
        const { force_change, password } = this.userPassword;
        const payload = {
          password,
          force_change,
          users: this.emails,
        };
        await this.changeUsersPassword(payload).then((error) => {
          errorCode = error;
          if (this.force_logout && !error) {
            this.forceUsersLogout({ users: this.emails });
          }
        });
      } else {
        await this.changeUserPassword(this.userPassword).then((error) => {
          errorCode = error;
          if (this.force_logout && !error) {
            this.forceLogout(this.userKey);
          }
        });
      }
      this.errorCode = errorCode;
      if (!errorCode) this.$emit("close");

      if (this.userHasAlreadySentFeedback && !errorCode) {
        this.feedbackDialog = true;
      }
    },

    initRules() {
      const keys = [];
      for (let key in this.passwordStrength) {
        if (this.passwordStrength[key]) {
          keys.push(key);
        }
      }
      this.rulesKeys = [...keys];

      this.passwordStrengthRules = this.rulesKeys.map((rule) => {
        return this.rules[rule];
      });
    },
  },
  beforeMount() {
    this.initRules();
  },
};
</script>
