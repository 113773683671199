<template>
  <ReportsPaginated
    ref="reports"
    titleKey="reports_access_time_title"
    :companyView="companyView"
    :getterUrl="getterUrl"
    :userEmail="userEmail"
    :formatter="formatLog"
    :headers="headers"
    :prepareData="prepareData"
    :showExpand="true"
    @tableOptions="tableOptions = $event"
  >
  </ReportsPaginated>
</template>
<script>
import ReportsPaginated from "@/components/logs/ReportsPaginated.vue";
import { formatHour, formatDayFullMonth } from "@/helpers/utils";
import { backendLogCodes, loginAttemptCodes } from "@/helpers/variables";
import * as moment from "moment";

export default {
  name: "ReportsAccessTime",
  components: { ReportsPaginated },
  props: {
    userEmail: { type: String },
    companyView: { type: Boolean, default: () => true },
  },
  data: () => {
    return {
      getterUrl: `${process.env.VUE_APP_API_BASE_URL}/logs/access-time`,
      loginAttemptCodes,
      backendLogCodes,
    };
  },
  computed: {
    headers() {
      return [
        {
          value: "day",
          width: 180,
          sortable: false,
        },
        {
          value: "author_user",
          sortable: false,
        },
        {
          value: "start",
          sortable: false,
        },
        {
          value: "end",
          sortable: false,
        },
        {
          value: "duration",
          sortable: false,
        },
        {
          value: "records",
          sortable: false,
        },
      ];
    },
    recordsHeaders() {
      return [
        {
          text: this.$ml.get("hour"),
          value: "hour",
          sortable: false,
        },
        {
          text: this.$ml.get("event"),
          value: "event",
          sortable: false,
        },
        {
          text: this.$ml.get("ip"),
          value: "ip",
          sortable: false,
        },
        {
          text: this.$ml.get("browser"),
          value: "browser",
          sortable: false,
        },
        {
          text: this.$ml.get("operational_system"),
          value: "operational_system",
          sortable: false,
        },
      ];
    },
  },
  methods: {
    formatHour,

    formatLog(item) {
      const day = new Date(`${item.created_at_day} UTC-3`);
      let start,
        startFix = "";
      let end,
        endFix = "";
      let duration,
        durFix = "";
      let last = {};
      let lookingNextFor = "";
      let state = "";
      const lastIndex = item.records.length - 1;
      const records = item.records
        .map((record) => {
          const code = record.code.toUpperCase();
          let type = "";
          if (this.loginAttemptCodes.includes(code)) {
            type = "Login";
          } else if (code in this.backendLogCodes) {
            type = "Logout";
          }
          const date_time = new Date(record.created_at.replace("GMT", "UTC-3"));
          date_time.setMilliseconds(0);
          date_time.setSeconds(0);
          return {
            ...record,
            date_time,
            code,
            type,
            event: this.$ml.get(code.toLowerCase()),
            hour: this.formatHour(date_time),
            show: false,
          };
        })
        .sort((a, b) => a.date_time - b.date_time)
        .map((record, index) => {
          const date_time = record.date_time;
          if (record.type === "Login") {
            start = date_time < (start || Infinity) ? date_time : start;
            if (["Login"].includes(last.type)) {
              duration = new Date(
                (duration || day).getTime() +
                  Math.abs(date_time - last.date_time)
              );
            }
            if (index == lastIndex) {
              const now = new Date();
              const nextDay = new Date(
                moment(day).add(1, "days").toDate().getTime() - 1
              );
              const dayIsNotToday = day.toDateString() != now.toDateString();
              const nextDateTime = dayIsNotToday ? nextDay : now;
              durFix = formatHour(
                new Date(
                  (duration || day).getTime() +
                    Math.abs(nextDateTime - date_time)
                )
              );
              endFix = "-";
              state = dayIsNotToday ? "MISSING_DATA" : "NOT_FINISHED";
            }
            lookingNextFor = "Logout";
          } else if (record.type === "Logout") {
            if (lookingNextFor == "Logout") {
              duration = new Date(
                (duration || day).getTime() +
                  Math.abs(date_time - last.date_time)
              );
              end = date_time > (end || -1) ? date_time : end;
              state = "COMPLETE";
            } else if (lookingNextFor != "Login") {
              startFix = "-";
              endFix = "-";
              durFix = "-";
              state = "CANNOT_CALCULATE";
            }
            lookingNextFor = "Login";
          }
          last = { ...record };
          return record;
        });
      return {
        ...item,
        day: (day && formatDayFullMonth(day)) || "-",
        author_user: item.user,
        start: startFix || (start && formatHour(start)) || "-",
        startFix,
        end: endFix || (end && formatHour(end)) || "-",
        endFix,
        duration: durFix || (duration && formatHour(duration)) || "-",
        durFix,
        state,
        headers: this.recordsHeaders,
        records,
        status: { code: "reports_access_time" },
      };
    },

    prepareData(data) {
      return data.map((item) => ({
        [this.$ml.get("date")]: item.day,
        [this.$ml.get("author_user")]: item.author_user,
        [this.$ml.get("start")]: item.start,
        [this.$ml.get("end")]: item.end,
        [this.$ml.get("duration")]: item.duration,
        [this.$ml.get("records")]: item.records.map(
          (record) =>
            `${record.event}: ${record.hour}` +
            ` (${this.$ml.get("ip")}: ${record.ip || "- "};` +
            ` ${this.$ml.get("browser")}: ${record.browser || "- "};` +
            ` ${this.$ml.get("operational_system")}: ${
              record.operational_system || "- "
            });`
        ),
      }));
    },
  },
};
</script>
