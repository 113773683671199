var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('HeaderDialog',{attrs:{"id":"single_access","title":_vm.singleAccessInfo.title,"show":_vm.show,"loading":_vm.loading,"disabledAction":_vm.defineAccessTime ? _vm.timeError : _vm.defineAccessTime,"width":"1000"},on:{"close":_vm.setDefaultDialogData,"action":function($event){_vm.singleAccessInfo.action === 'grant_single_access_dialog'
      ? _vm.grantSingleAccessDialog()
      : _vm.removeSingleAccessDialog()}},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('v-row',{staticClass:"ml-0 mr-0"},[_c('v-col',{staticClass:"px-0"},[_c('p',[_vm._v(" "+_vm._s(_vm.singleAccessInfo.description)+" ")])])],1),_c('v-row',[_c('v-card',{staticClass:"mx-auto rounded-lg mb-5",attrs:{"outlined":"","id":"user-logs","max-width":"1400","width":"98%"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.userLogs,"items-per-page":5,"hide-default-footer":"","loading":_vm.loadingLogs},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.$ml.get("no_data"))+" ")]},proxy:true},{key:"top",fn:function(){return [_c('v-card-title',{staticClass:"my-0",staticStyle:{"padding-top":"10px","padding-bottom":"0px"}},[_c('span',{staticClass:"mr-5"},[_vm._v(" "+_vm._s(_vm.$ml.get("history"))+" ")])])]},proxy:true},{key:"header.date",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$ml.get(header.value))+" ")]}},{key:"item.date",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateAndHour(item.created_at, true))+" ")]}},{key:"header.event",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$ml.get(header.value))+" ")]}},{key:"item.event",fn:function(ref){
      var item = ref.item;
return [_c('v-chip',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.getTranslatedContext(item.context) || item.event)+" ")])]}},{key:"item.target_user",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.status.target_user)+" ")]}},{key:"header.author_user",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$ml.get(header.value))+" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"grey darken-1","dark":"","small":""},domProps:{"textContent":_vm._s('mdi-help-circle')}},'v-icon',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("author_description")))])])]}},{key:"header.target_user",fn:function(ref){return [_vm._v(" "+_vm._s(_vm.$ml.get("user"))+" ")]}}],null,true)})],1)],1),(_vm.singleAccessInfo.action === 'grant_single_access_dialog')?_c('v-row',{staticClass:"ml-0 mr-0"},[_c('v-checkbox',{attrs:{"label":_vm.$ml.get('time_unit_checkbox_text')},model:{value:(_vm.defineAccessTime),callback:function ($$v) {_vm.defineAccessTime=$$v},expression:"defineAccessTime"}}),_c('v-tooltip',{attrs:{"top":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"size":"20"},domProps:{"textContent":_vm._s('mdi-help-circle-outline')}},'v-icon',attrs,false),on))]}}],null,false,2832485748)},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("time_unit_checkbox_help_text")))])])],1):_vm._e(),(_vm.singleAccessInfo.action === 'grant_single_access_dialog')?_c('v-row',{staticClass:"ml-0 mr-0"},[_c('v-col',[_c('v-text-field',{attrs:{"type":"number","min":"1","disabled":!_vm.defineAccessTime,"label":_vm.$ml.get('access_time'),"rules":[
            function (value) { return parseInt(value) > 0 || _vm.$ml.get('less_than_rule') + '1'; },

            function (value) { return (parseInt(value) > 0 && parseInt(value) <= 1000) ||
              _vm.$ml.get('greater_than_rule') + '1000'; } ]},on:{"update:error":function($event){_vm.timeError = $event}},model:{value:(_vm.singleAccessTime),callback:function ($$v) {_vm.singleAccessTime=$$v},expression:"singleAccessTime"}})],1),_c('v-col',[_c('v-select',{attrs:{"disabled":!_vm.defineAccessTime,"items":['hours', 'minutes'],"label":_vm.$ml.get('time_unit')},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$ml.get(item))+" ")]}},{key:"item",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$ml.get(item))+" ")]}}],null,false,581282108),model:{value:(_vm.singleAccessExpirationTimeUnit),callback:function ($$v) {_vm.singleAccessExpirationTimeUnit=$$v},expression:"singleAccessExpirationTimeUnit"}})],1)],1):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }