<template>
  <HeaderDialog
    id="single_access"
    :title="singleAccessInfo.title"
    :show="show"
    :loading="loading"
    @close="setDefaultDialogData"
    @action="
      singleAccessInfo.action === 'grant_single_access_dialog'
        ? grantSingleAccessDialog()
        : removeSingleAccessDialog()
    "
    :disabledAction="defineAccessTime ? timeError : defineAccessTime"
    width="1000"
  >
    <template v-slot:body>
      <v-row class="ml-0 mr-0">
        <v-col class="px-0">
          <p>
            {{ singleAccessInfo.description }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-card
          outlined
          id="user-logs"
          max-width="1400"
          class="mx-auto rounded-lg mb-5"
          width="98%"
        >
          <v-data-table
            :headers="headers"
            :items="userLogs"
            :items-per-page="5"
            class="elevation-1"
            hide-default-footer
            :loading="loadingLogs"
          >
            <template v-slot:no-data>
              {{ $ml.get("no_data") }}
            </template>
            <template v-slot:top>
              <v-card-title
                class="my-0"
                style="padding-top: 10px; padding-bottom: 0px"
              >
                <span class="mr-5"> {{ $ml.get("history") }} </span>
              </v-card-title>
            </template>
            <template v-slot:[`header.date`]="{ header }">
              {{ $ml.get(header.value) }}
            </template>
            <template v-slot:[`item.date`]="{ item }">
              {{ formatDateAndHour(item.created_at, true) }}
            </template>
            <template v-slot:[`header.event`]="{ header }">
              {{ $ml.get(header.value) }}
            </template>
            <template v-slot:[`item.event`]="{ item }">
              <v-chip small>
                {{ getTranslatedContext(item.context) || item.event }}
              </v-chip>
            </template>

            <template v-slot:[`item.target_user`]="{ item }">
              {{ item.status.target_user }}
            </template>

            <template v-slot:[`header.author_user`]="{ header }">
              {{ $ml.get(header.value) }}
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="grey darken-1"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    small
                    v-text="'mdi-help-circle'"
                  />
                </template>
                <span>{{ $ml.get("author_description") }}</span>
              </v-tooltip>
            </template>

            <template v-slot:[`header.target_user`]="{}">
              {{ $ml.get("user") }}
            </template>
          </v-data-table>
        </v-card>
      </v-row>
      <v-row
        class="ml-0 mr-0"
        v-if="singleAccessInfo.action === 'grant_single_access_dialog'"
      >
        <v-checkbox
          v-model="defineAccessTime"
          :label="$ml.get('time_unit_checkbox_text')"
        ></v-checkbox>
        <v-tooltip top max-width="300">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="ml-1"
              size="20"
              v-text="'mdi-help-circle-outline'"
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <span>{{ $ml.get("time_unit_checkbox_help_text") }}</span>
        </v-tooltip>
      </v-row>
      <v-row
        class="ml-0 mr-0"
        v-if="singleAccessInfo.action === 'grant_single_access_dialog'"
      >
        <v-col>
          <v-text-field
            type="number"
            min="1"
            :disabled="!defineAccessTime"
            :label="$ml.get('access_time')"
            :rules="[
              (value) => parseInt(value) > 0 || $ml.get('less_than_rule') + '1',

              (value) =>
                (parseInt(value) > 0 && parseInt(value) <= 1000) ||
                $ml.get('greater_than_rule') + '1000',
            ]"
            @update:error="timeError = $event"
            v-model="singleAccessTime"
          />
        </v-col>
        <v-col>
          <v-select
            :disabled="!defineAccessTime"
            :items="['hours', 'minutes']"
            v-model="singleAccessExpirationTimeUnit"
            :label="$ml.get('time_unit')"
          >
            <template v-slot:selection="{ item }">
              {{ $ml.get(item) }}
            </template>
            <template v-slot:item="{ item }">
              {{ $ml.get(item) }}
            </template>
          </v-select>
        </v-col>
      </v-row>
    </template>
  </HeaderDialog>
</template>
<script>
import { mapActions } from "vuex";
import { subtractMonths, formatDateAndHour } from "@/helpers/utils";
import { backendLogCodes } from "@/helpers/variables";

export default {
  name: "SingleAccessDialog",
  props: {
    show: { type: Boolean, required: true },
    user: { type: Object, required: true },
  },
  data() {
    return {
      defineAccessTime: false,
      singleAccessExpirationTimeUnit: "hours",
      singleAccessTime: 1,
      loading: false,
      timeError: false,
      userLogs: [],
      loadingLogs: false,
      headers: [
        {
          value: "date",
          sortable: false,
        },
        {
          value: "author_user",
          sortable: false,
        },
        {
          value: "event",
          sortable: false,
        },
        {
          value: "target_user",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    hasSingleAccess() {
      return this.user.has_single_access;
    },

    singleAccessInfo() {
      if (this.hasSingleAccess) {
        return {
          title: this.$ml.get("remove_single_access"),
          action: "remove_single_access_dialog",
          description: this.$ml.get("remove_single_access_description"),
        };
      }

      return {
        title: this.$ml.get("grant_single_access"),
        action: "grant_single_access_dialog",
        description: this.$ml.get("grant_single_access_description"),
      };
    },
  },
  methods: {
    ...mapActions(["removeSingleAccess", "grantSingleAccess"]),
    formatDateAndHour,
    removeSingleAccessDialog() {
      this.loading = true;
      this.removeSingleAccess(this.user.key)
        .then(({ data }) => this.$emit("update", data))
        .finally(() => {
          this.loading = false;
          this.setDefaultDialogData();
        });
    },

    getTranslatedContext(context) {
      const contexts = {
        lost_user_single_access: "removed_single_access_from",
        receive_user_single_access: "grantedSingleAccess",
        user_request_single_access: "requestSingleAccess",
      };

      return this.$ml.get(contexts[context]);
    },

    getEventDescription(eventCode) {
      if (eventCode === backendLogCodes.RECEIVE_USER_SINGLE_ACCESS) {
        return this.$ml.get("receive_user_single_access");
      } else if (eventCode === backendLogCodes.LOST_USER_SINGLE_ACCESS) {
        return this.$ml.get("removed_single_access_from");
      }
      eventCode = eventCode.toLowerCase();
      return this.$ml.get(eventCode);
    },

    formatLog(item) {
      return {
        ...item,
        event: this.getEventDescription(item.status.code),
        date: new Date(item.created_at),
        author_user: item.status.author_user || item.status.logged_out_user,
        ip: item.status.ip,
        browser: item.status.browser,
        operational_system: item.status.operational_system,
      };
    },

    async getLastLoginAndLogoutLogs() {
      this.loadingLogs = true;
      const fromDate = subtractMonths(6);
      const contexts = [
        "lost_user_single_access",
        "receive_user_single_access",
        "user_request_single_access",
      ];

      const url = `${process.env.VUE_APP_API_BASE_URL}/logs/users?page_index=1&rows_per_page=5&email=${this.user.email}&from_date=${fromDate}&contexts=${contexts}`;

      await this.$axios
        .get(url)
        .then(({ data }) => {
          this.userLogs = data.logs.map(this.formatLog);
        })
        .catch((error) => {
          console.error("getLastLoginAndLogoutLogs()", error);
        })
        .finally(() => (this.loadingLogs = false));
    },

    setDefaultDialogData() {
      this.singleAccessDialog = false;
      this.defineAccessTime = false;
      this.singleAccessExpirationTimeUnit = "hours";
      this.singleAccessTime = 1;
      this.timeError = false;
      this.$emit("close");
    },

    grantSingleAccessDialog() {
      this.loading = true;
      let payload = {};

      if (this.defineAccessTime) {
        payload = {
          single_access_time: this.singleAccessTime,
          time_unit: this.singleAccessExpirationTimeUnit,
        };
      } else {
        payload = {};
      }
      this.grantSingleAccess({
        userKey: this.user.key,
        payload,
      })
        .then(({ data }) => this.$emit("update", data))
        .finally(() => {
          this.loading = false;
          this.setDefaultDialogData();
        });
    },
  },

  watch: {
    show(value) {
      if (value) {
        this.getLastLoginAndLogoutLogs();
      } else {
        this.userLogs = [];
      }
    },
  },
};
</script>
