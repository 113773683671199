<template>
  <v-dialog
    v-model="showDialog"
    width="880"
    @keydown.esc="showDialog = false"
    @click:outside="showDialog = false"
  >
    <v-card class="d-flex" tile min-height="400">
      <v-img
        v-if="!$vuetify.breakpoint.smAndDown"
        :src="src"
        max-height="450"
        max-width="440"
      />
      <v-card
        tile
        flat
        class="px-4 d-flex flex-column align-center"
        width="100%"
      >
        <v-card-text
          class="d-flex flex-column align-center justify-center pt-8 pb-5"
          tile
        >
          <Logo
            noMargins
            full
            class="mx-auto"
            width="300"
            product="conecta_sign"
          />
        </v-card-text>
        <v-card-text class="text-center pt-0">
          <h3 class="font-weight-bold">
            {{ $ml.get("apply_vacation_message_title") }}
          </h3>
        </v-card-text>

        <v-card-text class="text-center pt-0 pb-1">
          <div class="text-body-1">
            <div>
              <p>
                {{ $ml.get("apply_vacation_message_subtitle") }}
              </p>
              <p>
                {{ $ml.get("apply_vacation_question") }}
              </p>
            </div>
          </div>
        </v-card-text>

        <v-card-actions
          class="d-flex flex-grow-1 flex-column align-center justify-center pt-auto mb-auto pb-auto"
        >
          <v-btn
            depressed
            color="#fabb09"
            large
            :class="`white--text text-none font-weight-bold text-body-1 px-8`"
            @click="$emit('action')"
            target="_blank"
          >
            {{ $ml.get("go_to_sign") }}
          </v-btn>
          <v-btn
            text
            color="secondary lighten-2"
            large
            :class="`text-none font-weight-bold rounded-lg text-body-1 pa-0 mt-2 mx-0`"
            @click="showDialog = false"
          >
            {{ $ml.get("dismiss") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-card>
  </v-dialog>
</template>
<script>
import { images } from "@/helpers/defaultImages";

export default {
  name: "VacationSignDialog",

  props: {
    show: { type: Boolean, required: true },
  },

  data() {
    return {
      sendAsName: "",
      sendAsEmail: "",
      sendAsEmailIsValid: false,
      src: images["conecta_sign"],
    };
  },

  computed: {
    showDialog: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("close");
      },
    },
  },
};
</script>
